<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'PratingDatetime', ascending: 0 }"
		modelId="PratingId"
		headerTitle="portalratings"
		tooltipMsg="manualforportal"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="PortalRatingsTable"
	>
		<template #PratingDatetime="list">{{ list.row.PratingDatetime | dateTime }}</template>

		<template #PratingPersonType="list">{{ formatType(list.row.PratingPersonType) }}</template>

		<template #PratingName="list">{{ list.row.PratingName }}</template>

		<template #PratingEmail="list">{{ list.row.PratingEmail }}</template>

		<template #PratingVisible="list">
			<boolean-display :boolValue="Boolean(list.row.PratingVisible)"></boolean-display>
		</template>

		<template #PratingTop="list">
			<boolean-display :boolValue="Boolean(list.row.PratingTop)" badgeTitle="TOP"></boolean-display>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="+list.row.CountryId"></country-flag>
		</template>

		<template #customActions="list">
			<megau-button
				classprop="btn btn-customdanger ml-1"
				icon="trash-alt"
				:tooltip="$t('delete')"
				@handleClick="openModal(list.row.PratingId)"
			></megau-button>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import { setDropdownOptions } from '@/components/general/utils';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '../../services/service/common.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './portal-rating.table-data';

export default {
	name: 'portalRatingTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'PortalRating',
			//columns: tableColumns,

			TypeDict: serviceEnums.getEnumDict('personType'),
		};
	},

	async created() {
		this.columns = this.prepareColumns(tableColumns);
	},

	methods: {
		prepareColumns(columns) {
			let values = serviceEnums.getEnumForDropdown('personType', true);
			return setDropdownOptions(columns, 'PratingPersonType', values);
		},

		formatType(type) {
			if (type > 0) {
				return this.TypeDict[type].Text;
			}
			return '-';
		},

		refresh() {
			this.tableKey += 1;
		},

		redirectToDetail(itemId = 0) {
			this.$router.push({
				name: links.portalRatingsDetailName,
				params: { PratingId: itemId },
			});
		},

		// handleCustomFilter() {
		// 	serviceCommon.setCustomFilter(this.filters, this.$options.name);
		// },

		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},

		openModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteThisItem);
		},

		deleteThisItem(id) {
			serviceCommon.deleteItem(id, this.tableGetData, this.controllerName);
		},
	},
};
</script>

<style lang="scss">
.PortalRatingsTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(8) {
		max-width: 100px;
	}
}
</style>
