export const tableColumns = [
			{
				model: 'PratingDatetime',
				i18n: 'created',
				sortable: true,
				filter: 'daterange',
			},
			{
				model: 'PratingPersonType',
				i18n: 'type',
				sortable: true,
				filter: 'dropdown',
				filterOptions: {
					dropdownOptions: [],
				},
			},
			{
				model: 'PratingName',
				i18n: 'name',
				sortable: true,
				filter: 'text',
			},
			{
				model: 'PratingEmail',
				i18n: 'e-mail',
				sortable: true,
				filter: 'text',
			},
			{
				model: 'PratingVisible',
				i18n: 'displayed',
				sortable: true,
				filter: 'boolean',
			},
			{
				model: 'PratingTop',
				i18n: 'top',
				sortable: true,
				filter: 'boolean',
			},
			{
				model: 'CountryId',
				i18n: 'country',
				sortable: true,
				filter: 'country',
			},
		];
