var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "PortalRatingsTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "PratingDatetime", ascending: 0 },
          modelId: "PratingId",
          headerTitle: "portalratings",
          tooltipMsg: "manualforportal",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "PratingDatetime",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm._f("dateTime")(list.row.PratingDatetime))),
                ]
              },
            },
            {
              key: "PratingPersonType",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm.formatType(list.row.PratingPersonType))),
                ]
              },
            },
            {
              key: "PratingName",
              fn: function (list) {
                return [_vm._v(_vm._s(list.row.PratingName))]
              },
            },
            {
              key: "PratingEmail",
              fn: function (list) {
                return [_vm._v(_vm._s(list.row.PratingEmail))]
              },
            },
            {
              key: "PratingVisible",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: { boolValue: Boolean(list.row.PratingVisible) },
                  }),
                ]
              },
            },
            {
              key: "PratingTop",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: {
                      boolValue: Boolean(list.row.PratingTop),
                      badgeTitle: "TOP",
                    },
                  }),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: +list.row.CountryId },
                  }),
                ]
              },
            },
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-customdanger ml-1",
                      icon: "trash-alt",
                      tooltip: _vm.$t("delete"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.openModal(list.row.PratingId)
                      },
                    },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          2500400979
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }